import React, { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { graphql, PageProps } from 'gatsby';
import { Col, Container } from 'react-bootstrap';

import Layout from 'components/Layout';
import ProductDetails from 'components/ProductDetails';
import ProductIntro from 'components/ProductIntro';
import ShopMessage from 'components/ShopMessage';
import ProductBanner from 'components/ProductBanner';
import Banner from 'components/Banner';
import ProductsFinder from 'components/ProductsFinder';
import RecentlyViewedProducts from 'components/RecentlyViewedProducts/RecentlyViewedProducts';
import { parseBanner } from 'components/Banner/parsers';
import RelatedProducts from 'components/RelatedProducts';
import { parseNFButton } from 'components/common/NFButton/parsers';
import MapStores, { IMapStoresData } from 'components/MapStores';
import { IRelatedElementsCarouselData } from 'components/RelatedElementsCarousel';
import Video from 'components/common/Video';
import ProductSchema from 'components/common/ProductSchema';
import AdditionalMessage from 'components/AdditionalMessage';

import { DEFAULT_PRODUCT_FAMILY_COLOR } from 'utils/constants';
import { getProductDescription, getAccordionContentByHeader } from 'utils/helpers';
import { getLSProducts, updateLSProducts } from 'utils/recentProductsHelper';
import extractUrlFromMultiPicker from 'utils/urlMultiPicker';
import { gtmService } from 'services/gtmService';

import { IProductVariant } from './models';

import './productPage.scss';
import './productPageOverride.scss';

const PRODUCT_LINK_ANCHOR_TITLE = 'ShopMessage';
const PRODUCT_DESCRIPTION = 'Product description';

const CMS_PRODUCT_VIDEO_POSITION = {
  'below product accordion': 'below product accordion',
  'below discover more': 'below discover more',
};
interface IProductPage extends PageProps {
  data: ProductTypes.IProduct;
  pageContext: {
    breadcrumbs: UmbracoTypes.IBreadCrumb[];
    relatedProducts: string[];
    maxMobileImageHaccoreight?: number;
    maxMobileImageHeight?: number;
  };
}

const MemoBanner = React.memo(Banner);

const ProductPage: FC<IProductPage> = ({
  data: {
    product,
    relatedProducts,
    carouselControls,
    header,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    productDosageCalculatorConfig,
    legalPopup,
    productQuiz,
    pageLangs: { nodes: langs },
  },
  pageContext,
}) => {
  const {
    banner,
    sku,
    link,
    lang,
    descriptionLong,
    descriptionShort,
    productVariants,
    accordionData,
    productDetails,
    productStartQuiz,
    productLinks,
    productVideo,
    pdpConfigs,
    productDosageCalculator,
    defaultProductTitle,
    defaultProductImage,
    pageBreakerBanner,
    shopMessage,
    recentlyViewedProducts,
    productFamily,
    preferred,
    productImageAlt,
    relatedProductsConfig,
    productMapStores,
    productMapStoresTrigger,
    productRelatedElementsTrigger,
    productRelatedElements,
    seoMetaDescription,
    seoMetaKeywords,
    seoMetaTitle,
    seoExternalHreflangs,
    customFooterCopyright,
    customFooterPhoneNumber,
    productRelatedImage,
    productVariant,
    pageName,
  } = product;
  const relatedElementsListData = productRelatedElements?.[0]?.properties;

  const [activeProduct, setActiveProduct] = useState<IProductVariant>({
    sku,
    title: defaultProductTitle,
    image: defaultProductImage,
    productLink: productLinks?.[0]?.properties,
    descriptionShort,
    descriptionLong,
    pharmaciesList: relatedElementsListData?.relatedElementsListItems || [],
  });

  const [recentProducts, setRecentProducts] = useState<ProductCard.IProductCard[]>();
  const [isProductLoaded, setProductLoaded] = useState(false);
  const changeActiveProduct = (productSku) => {
    const selectedProduct = productVariants?.filter(
      ({ properties }) => properties.variantSku === productSku
    );
    if (selectedProduct?.length) {
      const [
        {
          properties: {
            productTitle,
            descriptionShort: variantDescriptionShort,
            descriptionLong: variantDescriptionLong,
            variantDropdownValue,
            image,
            variantSku,
            yourPharmacyUrl,
            productVariantRelatedElements,
          },
        },
      ] = selectedProduct;
      setActiveProduct({
        title: productTitle,
        dropdownValue: variantDropdownValue,
        image,
        descriptionShort: variantDescriptionShort || descriptionShort,
        descriptionLong: variantDescriptionLong || descriptionLong,
        sku: variantSku,
        productLink: yourPharmacyUrl?.length
          ? {
              ...productLinks?.[0]?.properties,
              link: [
                {
                  ...productLinks?.[0]?.properties.link[0],
                  url: extractUrlFromMultiPicker(yourPharmacyUrl),
                },
              ],
            }
          : productLinks?.[0]?.properties,
        pharmaciesList:
          (productVariantRelatedElements?.length
            ? productVariantRelatedElements
            : relatedElementsListData?.relatedElementsListItems) || [],
      });
    }
  };

  useEffect(() => {
    if (productVariants?.length) {
      changeActiveProduct(sku);
    } else {
      setActiveProduct({
        sku,
        title: defaultProductTitle,
        image: defaultProductImage,
        productLink: productLinks?.[0]?.properties,
        descriptionShort,
        descriptionLong,
        pharmaciesList: relatedElementsListData?.relatedElementsListItems || [],
      });
    }
    const selectedProduct = productVariants?.filter(
      ({ properties }) => properties.variantSku === sku
    );
    const pharmacyUrl = selectedProduct?.length
      ? extractUrlFromMultiPicker(
          selectedProduct[0].properties.yourPharmacyUrl || productLinks?.[0]?.properties.link
        )
      : extractUrlFromMultiPicker(productLinks?.[0]?.properties.link);

    updateLSProducts(
      siteSettings.lang,
      {
        sku,
        defaultProductTitle,
        defaultProductImage,
        lang,
        link,
        descriptionLong,
        descriptionShort,
        preferred,
        productFamily,
        productImageAlt,
        productVariant,
        productLinks: pharmacyUrl
          ? [
              {
                properties: {
                  ...productLinks?.[0]?.properties,
                  link: [
                    {
                      ...productLinks?.[0]?.properties.link[0],
                      url: pharmacyUrl,
                    },
                  ],
                },
              },
            ]
          : productLinks,
      },
      sku
    );
    setRecentProducts(
      getLSProducts(siteSettings.lang).filter((lsProduct) => lsProduct.sku !== sku)
    );
    setProductLoaded(true);
  }, []);

  const accordionItems = useMemo(() => {
    const accordionPanelsFilled = productDetails?.[0]?.properties?.accordion.filter(
      (panel) => panel.content?.length
    );
    let firstOpenedTab = accordionPanelsFilled.findIndex((item) => item.isOpened === '1');
    firstOpenedTab = firstOpenedTab === -1 ? 0 : firstOpenedTab;

    return accordionPanelsFilled.map((item, i) => ({
      ...item,
      isOpened: firstOpenedTab === i ? '1' : '0',
      index: i,
    }));
  }, [productDetails?.[0]?.properties?.[0]?.accordion]);

  const variantsDropdown = useMemo(() => {
    return productVariants?.length
      ? {
          label: pdpConfigs.productVariantDropdownLabel,
          productVariants: productVariants.map(({ properties }) => ({
            ...properties,
            isActive: properties.variantSku === activeProduct.sku,
          })),
          defaultProductSku: sku,
        }
      : null;
  }, [activeProduct.sku]);

  const recentProductsSection = useMemo(() => {
    return recentProducts?.length && recentlyViewedProducts?.length ? (
      <RecentlyViewedProducts
        title={
          recentlyViewedProducts?.[0]?.properties?.recentlyViewedTitle ||
          pdpConfigs.recentlyViewedTitle
        }
        bgColor={
          recentlyViewedProducts?.[0]?.properties?.sectionBg?.[0]?.properties?.colorPicker?.label ||
          'grey'
        }
        products={recentProducts}
        shopBtnShow={recentlyViewedProducts?.[0]?.properties.shopBtnsShow === '1'}
        btn={
          recentlyViewedProducts?.[0]?.properties?.sectionCtaBtn?.length
            ? parseNFButton(recentlyViewedProducts?.[0]?.properties?.sectionCtaBtn?.[0]?.properties)
            : undefined
        }
        fontColor={
          recentlyViewedProducts?.[0]?.properties?.sectionFontColor?.[0]?.properties?.colorPicker
            ?.label || 'navy'
        }
        carouselControls={carouselControls}
        pageName={pageName}
      />
    ) : null;
  }, [recentProducts]);

  const relatedProductsSection = useMemo(() => {
    return relatedProducts.nodes?.length ? (
      <RelatedProducts
        title={relatedProductsConfig?.[0]?.properties?.relatedProductsTitle}
        showBtn={relatedProductsConfig?.[0]?.properties?.shopBtnsShow === '1'}
        btn={
          relatedProductsConfig?.[0]?.properties?.sectionCtaBtn?.length
            ? parseNFButton(relatedProductsConfig?.[0]?.properties?.sectionCtaBtn?.[0]?.properties)
            : undefined
        }
        products={relatedProducts.nodes}
        productsLinks={pageContext?.relatedProducts}
        carouselControls={carouselControls}
        listingName={pageName}
      />
    ) : null;
  }, []);

  const mapStoresData = productMapStores?.[0]?.properties;
  const mapStoresTriggerData = productMapStoresTrigger?.[0]?.properties;
  const mapStoresProps: IMapStoresData | undefined = mapStoresData
    ? {
        title: mapStoresData.mapStoresTitle,
        text: mapStoresData.mapStoresText,
        CTABtn: mapStoresData.mapStoresCTASearchButton?.length
          ? {
              ...parseNFButton(mapStoresData.mapStoresCTASearchButton[0].properties),
            }
          : undefined,
        searchInputTitle: mapStoresData.mapStoresSearchInputTitle,
        closeButtonTitle: mapStoresData.mapStoresCTACloseButtonTitle,
        closeButtonAriaLabel: mapStoresData.mapStoresCTACloseButtonAriaLabel,
        mapImageAlt: mapStoresData.mapStoresMapImageAlt,
        clearButtonAriaLabel: mapStoresData.mapStoresCTAClearButtonAriaLabel,
        mapImage: mapStoresData.mapStoresMapImage,
        searchTarget: mapStoresData.mapStoresSearchTarget,
        searchMapUrl: mapStoresData.mapStoresSearchMapUrl,
        submenuItemAnchor: mapStoresData.mapStoresSubmenuLink?.[0]?.properties?.submenuItemAnchor,
      }
    : undefined;

  const relatedElementsListProps: IRelatedElementsCarouselData | undefined = useMemo(
    () =>
      relatedElementsListData
        ? {
            title: relatedElementsListData.relatedElementsListTitle,
            CTABtn: relatedElementsListData.relatedElementsListCTAButton?.length
              ? {
                  ...parseNFButton(
                    relatedElementsListData.relatedElementsListCTAButton[0].properties
                  ),
                }
              : undefined,
            items: activeProduct.pharmaciesList,
            submenuItemAnchor:
              relatedElementsListData.relatedElementsListSubmenuLink?.[0]?.properties
                ?.submenuItemAnchor,
            closeButtonAriaLabel:
              relatedElementsListData.relatedElementsListCTACloseButtonAriaLabel,
            closeButtonTitle: relatedElementsListData.relatedElementsListCTACloseButtonTitle,
            carouselControls,
          }
        : undefined,
    [activeProduct.pharmaciesList]
  );
  const relatedElementsTriggerData = productRelatedElementsTrigger?.[0]?.properties;

  const productVideoSection = useMemo(() => {
    return {
      isExist: (videoPostion) => {
        return (
          productVideo?.length &&
          !!parseInt(productVideo[0].properties?.isVideoShown, 10) &&
          productVideo[0].properties?.videoPosition.toLowerCase() === videoPostion
        );
      },
      component: () => {
        const videoBlock = productVideo?.[0]?.properties;
        const videoContent = productVideo?.[0]?.properties?.videoContent?.[0]?.properties;
        const isFullContainerWidthVideo =
          videoBlock?.isFullContainerWidthVideo !== undefined
            ? videoBlock.isFullContainerWidthVideo
            : true;

        return videoBlock?.videoContent?.length &&
          videoContent?.videoLink &&
          videoContent?.btnPlay?.svg?.content &&
          videoContent?.previewImg?.[0]?.properties?.featuredImage?.fallbackUrl ? (
          isFullContainerWidthVideo ? (
            <Video
              btnPlay={videoContent.btnPlay.svg.content}
              previewImg={videoContent.previewImg[0].properties}
              video={videoContent.videoLink}
            />
          ) : (
            <Container fluid>
              <Col sm="12" md={{ span: 10, offset: 1 }}>
                <Video
                  btnPlay={videoContent.btnPlay.svg.content}
                  previewImg={videoContent.previewImg[0].properties}
                  video={videoContent.videoLink}
                />
              </Col>
            </Container>
          )
        ) : null;
      },
    };
  }, [productVideo]);

  if (customFooterCopyright) {
    footer.copyrightInfo[0].properties.copyrightInfo = customFooterCopyright;
  }
  if (customFooterPhoneNumber) {
    footer.contactInfo[0].properties.contacts[0].properties.contactPhone = customFooterPhoneNumber;
  }

  const bgBannerColor =
    productFamily?.[0]?.productBannerBg?.[0]?.properties?.colorPicker?.label ||
    DEFAULT_PRODUCT_FAMILY_COLOR;

  useEffect(() => {
    if (!activeProduct.sku) return;
    const timeoutId = gtmService.emitProductView({ ...activeProduct, preferred, productVariant });

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [activeProduct]);

  const handleRetailerClick = useCallback(
    (productData: IProductVariant) => (shopName: string) => {
      gtmService.emitRetailerClick({ ...productData, preferred, productVariant }, shopName);
    },
    []
  );

  return (
    <Layout
      seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs }}
      header={header}
      footer={footer}
      siteSettings={siteSettings}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      legalPopup={legalPopup}
      langSettings={{
        currentLang: lang,
        langs,
      }}
      className="product-detail-page"
    >
      <ProductSchema
        name={activeProduct.title}
        url={`${siteSettings.siteUrl}${link.replace(/^\//g, '')}`}
        description={getAccordionContentByHeader(accordionItems, PRODUCT_DESCRIPTION)}
        brand={brandSettings.brandName}
        image={activeProduct.image?.fallbackUrl as string}
      />
      <ProductBanner
        {...{ productRelatedImage }}
        bgBannerColor={
          productFamily?.[0]?.productBannerBg?.[0]?.properties?.colorPicker?.label ||
          DEFAULT_PRODUCT_FAMILY_COLOR
        }
        breadcrumbs={pageContext.breadcrumbs}
        title={activeProduct.title || defaultProductTitle}
        description={
          getProductDescription(activeProduct.descriptionLong, activeProduct.descriptionShort) || ''
        }
        descriptionShort={activeProduct.descriptionShort}
        alsoAvailable={banner?.[0]?.properties?.alsoAvailable}
        alsoAvailablePosition={banner?.[0]?.properties?.alsoAvailablePosition}
        img={activeProduct.image ? activeProduct.image : productRelatedImage}
        imgAlt={productImageAlt}
        featureList={banner?.[0]?.properties?.features}
        ariaLabel={banner?.[0]?.properties?.ariaLabel}
        maxMobileImageHeight={
          pageContext?.maxMobileImageHaccoreight || pageContext?.maxMobileImageHeight
        }
        isProductLoaded={isProductLoaded}
      />
      <ProductIntro
        variantsDropdown={variantsDropdown}
        changeActiveProduct={changeActiveProduct}
        productLink={productVariants[0]?.properties?.yourPharmacyUrl[0]?.url}
        anchorTargetId={PRODUCT_LINK_ANCHOR_TITLE}
        description={getProductDescription(descriptionLong, descriptionShort) || ''}
        alsoAvailable={banner?.[0]?.properties?.alsoAvailable}
        mapStoresTriggerBtnData={
          mapStoresTriggerData?.mapStoresTriggerMapStoresCTA?.[0]?.properties
            ? parseNFButton(mapStoresTriggerData?.mapStoresTriggerMapStoresCTA?.[0]?.properties)
            : undefined
        }
        mapStoresProps={mapStoresProps ? { ...mapStoresProps } : undefined}
        mapStoresTriggerIcon={mapStoresTriggerData?.mapStoresTriggerMapStoresCTAIcon}
        relatedElementsTriggerBtnData={
          relatedElementsTriggerData?.relatedElementsTriggerCTA?.[0]?.properties
            ? parseNFButton(relatedElementsTriggerData?.relatedElementsTriggerCTA?.[0]?.properties)
            : undefined
        }
        relatedElementsTriggerIcon={relatedElementsTriggerData?.relatedElementsTriggerCTAIcon}
        relatedElementsListProps={relatedElementsListProps}
        relatedElementsTriggerActiveText={
          relatedElementsTriggerData?.relatedElementsTriggerActiveText
        }
        isProductLoaded={isProductLoaded}
        retailerClickHandler={handleRetailerClick(activeProduct)}
      />
      <ProductDetails
        brands={productDetails?.[0]?.properties?.infographicIcons}
        accordionItems={accordionItems}
        accordingDetails={accordionData}
        accordionDownloadSection={productDetails?.[0]?.properties?.accordionDownloadSection}
        medicalInfo={productDetails?.[0]?.properties?.medicalInfo}
        dosageCalc={{ variants: productDosageCalculator, labels: productDosageCalculatorConfig }}
      />
      {productVideoSection.isExist(CMS_PRODUCT_VIDEO_POSITION['below product accordion'])
        ? productVideoSection.component()
        : null}
      <MemoBanner {...parseBanner(pageBreakerBanner)} titleTag="h2" />
      {shopMessage?.length ? (
        <>
          <div id={PRODUCT_LINK_ANCHOR_TITLE} />
          <ShopMessage
            linkText={shopMessage?.[0]?.properties?.shopMessageLink?.[0]?.name}
            linkUrl={extractUrlFromMultiPicker(shopMessage?.[0]?.properties?.shopMessageLink)}
            title={shopMessage?.[0]?.properties?.shopMessageTitle}
            blocks={shopMessage?.[0]?.properties?.shopMessageIcons}
            shopMessageBgColor={
              shopMessage?.[0]?.properties?.shopMessageBgColor?.[0]?.properties?.colorPicker
                ?.label || undefined
            }
            shopMessageFontColor={
              shopMessage?.[0]?.properties?.shopMessageFontColor?.[0]?.properties?.colorPicker
                ?.label || undefined
            }
          />
        </>
      ) : null}
      {relatedProductsSection}
      {productVideoSection.isExist(CMS_PRODUCT_VIDEO_POSITION['below discover more'])
        ? productVideoSection.component()
        : null}
      {recentProductsSection}
      {mapStoresProps ? <MapStores {...mapStoresProps} /> : null}
      {productStartQuiz && productQuiz ? (
        <div
          className="products-finder-pdp-container"
          style={
            bgBannerColor &&
            ({
              backgroundColor: `var(--${bgBannerColor})`,
            } as React.CSSProperties)
          }
        >
          <ProductsFinder
            startQuiz={{
              startQuizBoldTitle: productStartQuiz?.[0]?.properties?.startQuizBoldTitle,
              startQuizLightTitle: productStartQuiz?.[0]?.properties?.startQuizLightTitle,
              startQuizAriaLabel: productStartQuiz?.[0]?.properties?.startQuizAriaLabel,
            }}
            productQuizData={productQuiz}
            ariaLabelPrev={carouselControls.ariaLabelPrev}
            ariaLabelNext={carouselControls.ariaLabelNext}
            lang={lang}
          />
        </div>
      ) : null}
      {pdpConfigs?.additionalMessage ? (
        <AdditionalMessage text={pdpConfigs.additionalMessage} />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query(
    $lang: String!
    $link: String!
    $relatedProducts: [String]
    $pageBannerImageSize: Int = 1000
    $maxImageWidth: Int
    $maxMobileImageHeight: Int
    $pageIdRegex: String
  ) {
    product: umbracoProduct(link: { eq: $link }, lang: { eq: $lang }) {
      productRelatedImage {
        fallbackUrl
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 256, fit: CONTAIN, background: "rgba(0,0,0,0)") {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      ...FragmentSeo
      ...FragmentProductBanner
      productFamily {
        ...FragmentProductFamily
      }
      productQuizTags {
        name
      }
      pageBreakerBanner {
        ...FragmentBanner
      }
      productStartQuiz {
        properties {
          startQuizBoldTitle
          startQuizLightTitle
          startQuizAriaLabel
        }
      }
      sku
      link
      lang
      preferred {
        title
      }
      descriptionLong
      descriptionShort
      defaultProductTitle
      seoMetaDescription
      pageName
      productImageAlt
      defaultProductImage {
        fallbackUrl
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: $maxImageWidth, fit: CONTAIN, background: "rgba(0,0,0,0)") {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mobileImage: gatsbyImage {
          childImageSharp {
            fluid(maxHeight: $maxMobileImageHeight, fit: CONTAIN, background: "rgba(0,0,0,0)") {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      productVariant
      productVariants {
        properties {
          productTitle
          variantDropdownValue
          variantSku
          descriptionShort
          descriptionLong
          image {
            fallbackUrl
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: $maxImageWidth, fit: CONTAIN, background: "rgba(0,0,0,0)") {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            mobileImage: gatsbyImage {
              childImageSharp {
                fluid(maxHeight: $maxMobileImageHeight, fit: CONTAIN, background: "rgba(0,0,0,0)") {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          yourPharmacyUrl {
            url
          }
          productVariantRelatedElements {
            properties {
              ...FragmentRelatedElement
            }
          }
        }
      }
      accordionData {
        productDescription {
          name
          isOpened
          descriptionProduct
        }
        productUsage {
          name
          isOpened
          descriptionProduct
        }
        productWarnings {
          name
          isOpened
          descriptionProduct
        }
        productIngredients {
          name
          isOpened
          descriptionProduct
        }
      }

      productDetails {
        properties {
          ...FragmentAccordion
          accordionDownloadSection {
            properties {
              accordionLinkToPDF {
                fallbackUrl
                gatsbyDocFile {
                  relativePath
                }
              }
              accordionDownloadIcon {
                fallbackUrl
                svg {
                  content
                }
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 40) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              accordionDownloadIconAlt
              accordionDownloadLabel
              accordionDownloadIconAction
              documentName
            }
          }
          infographicIcons {
            properties {
              text
              icon {
                fallbackUrl
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                svg {
                  content
                }
              }
            }
          }
          ...FragmentMedicalInfo
        }
      }
      productLinks {
        properties {
          ...FragmentProductLink
        }
      }
      productVideo {
        properties {
          isVideoShown
          isFullContainerWidthVideo
          videoPosition
          videoContent {
            properties {
              ...FragmentVideoBlock
            }
          }
        }
      }
      shopMessage {
        properties {
          shopMessageTitle
          shopMessageIcons {
            properties {
              label
              icon {
                fallbackUrl
                svg {
                  content
                }
              }
            }
          }
          shopMessageLink {
            url
            name
          }
          shopMessageBgColor {
            ...FragmentColorProps
          }
          shopMessageFontColor {
            ...FragmentColorProps
          }
        }
      }
      pdpConfigs {
        productVariantDropdownLabel
        productDescription
        productUsage
        productWarnings
        productIngredients
        recentlyViewedTitle
        additionalMessage
      }
      recentlyViewedProducts {
        properties {
          ...FragmentRecentlyViewedProducts
        }
      }
      relatedProductsConfig {
        properties {
          ...FragmentRelatedProductsConfig
        }
      }
      productMapStores {
        properties {
          ...FragmentMapStores
        }
      }
      productMapStoresTrigger {
        properties {
          ...FragmentMapStoresTrigger
        }
      }
      productRelatedElementsTrigger {
        properties {
          ...FragmentRelatedElementsTrigger
        }
      }
      productRelatedElements {
        properties {
          ...FragmentRelatedElementsList
        }
      }
      ...FragmentProductDosageCalc
      customFooterCopyright
      customFooterPhoneNumber
    }
    relatedProducts: allUmbracoProduct(
      filter: { link: { nin: [$link], in: $relatedProducts }, lang: { eq: $lang } }
      limit: 6
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    carouselControls: siteSettings(lang: { eq: $lang }) {
      ariaLabelNext
      ariaLabelPrev
    }
    productQuiz {
      ...FragmentProductQuiz
    }
    pageLangs: allUmbracoProduct(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    productDosageCalculatorConfig {
      ...FragmentProductDosageCalcConfig
    }
    ...FragmentCommonCompositions
  }
`;

export default ProductPage;
