import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import classNames from 'classnames';

import BreadCrumbs from 'components/BreadCrumbs';
import DangerouslySetInnerHTML from 'components/common/DangerouslySetInnerHtml';
import ProductFeatureList from 'components/ProductFeatureList';
import ProductImageGlowEffect from 'components/ProductImageGlowEffect';
import UniversalImage from 'components/common/UniversalImage';
import ProductBannerTitle from 'components/ProductBanner/ProductBannerTitle';
import ProductBannerDescription from 'components/ProductBanner/ProductBannerDescription';

import { IProductBannerComponent } from './model';

import './ProductBanner.scss';

const ProductBanner: FC<IProductBannerComponent> = ({
  breadcrumbs,
  title,
  description,
  descriptionShort,
  alsoAvailable,
  alsoAvailablePosition,
  img,
  imgAlt,
  bgBannerColor,
  featureList,
  ariaLabel,
  maxMobileImageHeight,
  productRelatedImage,
  isProductLoaded,
}): React.ReactElement => {
  return (
    <section
      className={classNames('nf-product-banner', {
        'product-loading': !isProductLoaded,
      })}
      style={
        bgBannerColor &&
        ({
          backgroundColor: `var(--${bgBannerColor})`,
        } as React.CSSProperties)
      }
      data-test="ProductBanner"
      aria-label={ariaLabel}
    >
      <Container fluid>
        <div className="nf-product-banner__frame">
          <ProductImageGlowEffect
            img={img}
            imgAlt={imgAlt}
            maxMobileImageHeight={maxMobileImageHeight}
          />
          <UniversalImage img={productRelatedImage} alt="" />
          <div className="nf-product-banner__product-info">
            {breadcrumbs ? <BreadCrumbs data={breadcrumbs} /> : null}
            <div className="nf-product-banner__info-frame">
              <ProductBannerTitle title={title} />
              <ProductBannerDescription
                description={description}
                descriptionShort={descriptionShort}
              />
              {!alsoAvailablePosition || alsoAvailablePosition?.includes('above') ? (
                <DangerouslySetInnerHTML
                  className="nf-product-banner__product-text"
                  html={alsoAvailable}
                />
              ) : null}
              <ProductFeatureList featureList={featureList} />
              {alsoAvailablePosition?.includes('below') ? (
                <DangerouslySetInnerHTML
                  className="nf-product-banner__product-text"
                  html={alsoAvailable}
                />
              ) : null}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export const query = graphql`
  fragment FragmentProductBanner on UmbracoProduct {
    banner {
      properties {
        alsoAvailable
        alsoAvailablePosition
        features {
          properties {
            featureTitle
            icon {
              fallbackUrl
              svg {
                content
              }
            }
            label
          }
        }
        imageBanner {
          url
        }
        ariaLabel
      }
    }
  }
`;

export default ProductBanner;
